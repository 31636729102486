import { memo } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { getTranslations } from '@/context'
import { HeadTag, Header, Main, Footer } from '@/components'

type TypeHomePage = {
  data: any,
  translations?: any
}

export async function getStaticProps({ locale }) {
  const dictionary = getTranslations(locale, ['home', 'form'])

  // const [[data, error], [layout, layoutError]] = await Promise.all([
  //   await fetchAPI('home'),
  //   await fetchAPI('layout')
  // ])

  // if (error || layoutError) return { notFound: true }

  return {
    props: {
      data: {
        seo: {
          title: 'Sablos - People and their PLACES',
          description: 'We talk to the world\'s most inspiring people about the places they love in the cities they live.'
        }
      },
      translations: dictionary
    }
  }
}

const HomePage: NextPage<TypeHomePage> & { Page?: FC } = memo(({ data }) => {
  const { seo } = data

  return (
    <>
      <HeadTag seo={seo} jsonld={{ data, pageType: 'home' }} pageType='home' />
      <Header />
      <Main />
      <Footer />
    </>
  )
})

export default HomePage
